@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.page, .video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
}

.menu-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 1;
  padding: 24px;
}

button {
  color: var(--Blurple-Primary, #6746F5);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  background-color: transparent;
  border: none;
  display: flex;
  cursor: pointer;
}

.next img{
 transform: rotate(180deg);
}

p {
  color: #000;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.current-highlighted,.currentHighlighted,.rpv-search__highlight--current {
  background-color: #795CF6;
  opacity: 0.8 !important;
}
.highlighted,.rpv-search__highlight {
  background-color: #795CF6 !important;
  opacity: 0.5 !important;
}
.highlighted-off{
  background-color:transparent !important;

}
.searchCSV-Con {
  display: flex;
  justify-content: center;
  height: 40px;
  position: sticky;
  top: 0;
  background-color: white; 
  z-index: 10; 
}

.scrollable-table-container {
  max-height: calc(100vh - 40px); 
  overflow-y: auto;
}

.fade-in { 
  animation: fadeIn 300ms ease-out; 
}

.fade-out{
  animation: fadeOut 400ms ease-out;
}
.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 900px;
}

.search-bar input {
  width: 200px;
  padding: 5px;
  margin-right: 10px;
}

.search-bar button {
  padding: 5px 10px;
  cursor: pointer;
}
.searchButton{
  float: left;
  padding: 10px;
  background: #2196F3;
  color: white;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
}
.nextTap,.searchContainer{
  display: flex;
}
.nextTap{
  position: relative;
  bottom: 2px;
}

@keyframes fadeIn {
  0% {
    opacity: 0.3;
  }

  100% {
    opacity: 0.8;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.3;
  }
}
.adobe-viewer-container {
  height: 100vh;
  overflow-y: auto;
  position: relative;
  background-color: #f0f0f0; /* Adjust this color to match your app's theme */
}

.adobe-dc-view {
  width: 100%;
  height: 100%;
}
